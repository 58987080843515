@use '@matillion/component-library/dist/assets/styles/colours' as *;

.Page {
  &__Title {
    padding-bottom: 42px;
  }
}

.Filters {
  &__Main {
    padding-left: 0;
  }

  &__Search {
    margin-left: auto;
    padding-left: 0;
  }
}

.Panel {
  padding: 18px;

  .Icons {
    width: 32px;
    padding: 0;
  }

  &__ToggleEnable {
    &__Label {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        margin-bottom: 0;
      }
    }
  }

  &__Button {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: $black;

    &:hover:not([disabled]) {
      color: $black;
    }

    &--danger:not([disabled]) {
      &:hover {
        color: $fire-red;
      }
    }
  }
}

.Popover {
  max-width: 20rem;
}

.Actions {
  &__Container {
    flex: 0 1;
  }

  &__CTA {
    gap: 8px;
    display: flex;
    flex-direction: row;
  }
}

.CronViewer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &__Image {
    width: 5em;
    height: 5em;
    margin-right: 10px;
    border-radius: 50%;
  }

  &__Contents {
    text-align: left;
  }
}
