@use '@matillion/component-library/dist/assets/styles/colours' as *;
@use '@matillion/component-library/dist/assets/styles/fonts' as fonts;
@use '@matillion/component-library/dist/assets/styles/spacing' as spacing;

@import '../../variables';
@import '../../mixins/visuallyHidden';

.SaaSScheduler {
  &__Form {
    margin: 0 auto;
  }

  &__JobInformation {
    margin-top: 40px;
    margin-bottom: 80px;
  }

  .EnableToggle {
    margin-top: 30px;

    label {
      display: flex;
      align-items: center;
      text-align: center;
    }

    span {
      @include fonts.make-label;
      margin-right: 12px;
      margin-bottom: 0;
    }

    &--Active {
      [class*='Toggle__OverlayText'] {
        padding-left: 3 * spacing.$base-spacing-unit;
      }
    }
  }

  .ScheduleSelector {
    margin-top: 40px;
    margin-bottom: 64px;
  }

  .Header {
    &__title {
      text-align: center;
    }

    &__actionRight {
      display: flex;
      justify-content: flex-end;
    }
  }

  fieldset {
    border: 0;
  }

  .CronFieldContainer {
    margin-top: 40px;
  }

  legend {
    @include u-visually-hidden;
  }

  .SpacingStyles {
    margin-top: 30px;
  }

  .WeekDay {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
  }

  .ExpireToggle {
    display: flex;
    align-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;

    &__Button {
      margin-right: 10px;
    }

    &__Legend {
      min-width: $form-label-split;
      padding: 0;
    }

    &__Input {
      flex-grow: 1;
    }
  }

  .TestSchedule {
    display: flex;

    &__Container {
      min-width: $form-label-split;
    }

    &__Feedback {
      flex-grow: 2;
      margin-left: $form-label-split;
      padding: 7px;
      overflow: auto;
      border: 1px solid #222;
      background-color: #f2f2f2;
    }
  }

  .Actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 128px;
    padding: 0;
  }

  .JobInformation {
    // TODO: this is a workaround that hides the DataGrid header. This component will eventually be replaced by a DefinitionList component.
    // remove this workaround when this ticket is implemented: https://matillion.atlassian.net/browse/DL-347
    > div:first-child {
      display: none;
    }
  }
}

.AutoCompleteGrouped {
  [class='AutoComplete-module__AutoComplete__Item'],
  [class*='AutoComplete__Item--Highlight'],
  [class*='AutoComplete__Item--Current'] {
    padding-left: 4 * spacing.$base-spacing-unit;
  }

  [class*='AutoComplete__Item--Disabled'] {
    color: $black;
    font-weight: bold;
  }
}
