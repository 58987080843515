@use '@matillion/component-library/dist/assets/styles/colours' as *;
@import '../../mixins/visuallyHidden';

.CheckboxSelector {
  margin-top: 24px;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &__label {
    width: 100%;
    min-width: 70px;
    margin-right: 8px;
    text-align: center;
    cursor: pointer;
  }

  &__checkbox {
    @include u-visually-hidden;

    &:checked + .CheckboxSelector__button {
      background-color: $plasma-green;
      color: $white;
    }

    &:focus + .CheckboxSelector__button {
      border: 2px solid $black;
    }
  }

  &__button {
    padding: 8px 16px;
    border: 2px solid transparent;
    border-radius: 70px;
    background: $misty;
    color: $black;
  }

  &__selectAllButton {
    min-width: 100px;
    margin-left: 8px;
  }

  .hideVisually {
    @include u-visually-hidden;
  }
}
