@use '@matillion/component-library/dist/assets/styles/colours' as *;

.Error {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 65vh;
  margin: auto;
  text-align: center;

  &__Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 470px;
    margin: 60px auto;
  }

  &__Title {
    margin-bottom: 30px;
  }

  &__Logo {
    width: 150px;
  }

  &__EmailBtn {
    min-width: 40%;
  }

  &__Description {
    margin-bottom: 30px;
  }

  &__Action {
    margin: auto;
  }

  &__ContactUs {
    margin-top: auto;

    &__Link {
      color: $electric-green;

      &:hover,
      &:focus {
        color: $plasma-green;
      }
    }
  }

  &__Emphasis {
    color: $electric-green;
  }
}

.ProblemDetails {
  dl {
    //margin-left: 16px;
    text-align: left;
  }

  dd {
    margin-left: revert;
  }
}
