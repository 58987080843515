@use '@matillion/component-library/dist/assets/styles/colours' as colors;

.DefinitionList {
  &__item {
    display: flex;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid colors.$cloudy;
  }

  &__title {
    min-width: 30%;
    color: colors.$hurricane;
  }

  &__description {
    margin-left: auto;
    text-align: right;
  }
}
