.Panel {
  &__Error {
    padding: 1em;

    ul {
      padding-inline-start: 48px;
      text-align: left;
    }
  }
}
