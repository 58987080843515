.Modal {
  &__Heading {
    width: 80%;
    margin: 0 auto 24px;
    text-align: center;
  }

  &__Title {
    font-size: 36px;
    line-height: 1em;
  }

  &__Actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 32px auto 0;
  }
}
